 <template>
  <div>
    <div class="container-fluid">
      <PageTitle :noAdd="true" />

      <b-tabs>
        <template v-for="(vlang,klang) in lang">
          <b-tab :title="`Page Settings - ${vlang.text}`" :key="klang">
            <bo-card title="Hero Image" footer-tag="footer">
              <b-row class="gutter-2">
                <b-col md="3">
                  <bo-card-img
                    :src="uploader(((content['hero_' + vlang.value] || {}).value || {}).img)"
                    :title="((content['hero_' + vlang.value] || {}).value || {}).title"
                    :isTitleHtml="true"
                  >
                    <template #buttons>
                      <b-button
                        v-if="moduleRole('Edit')"
                        variant="secondary"
                        size="sm"
                        pill
                        @click="editHeroImage(content['hero_' + vlang.value])"
                        v-b-tooltip.hover="'Edit'"
                      >
                        <i class="fas fa-pencil-alt" />
                      </b-button>
                    </template>
                  </bo-card-img>
                </b-col>
              </b-row>
            </bo-card>

            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h5 class="card-title">Introduction Section</h5>
                  </div>
                  <div class="col-6 ml-auto">
                    <div class="text-right">
                      <button
                        @click.prevent="toggleEditForm(`introduction_section_${vlang.value}`)"
                        class="btn btn-primary btn-rounded"
                      >
                        <i class="fas fa-edit m-r-10"></i> Update Content
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <ValidationObserver
                      v-slot="{ handleSubmit }"
                      :ref="`VFormIntroduction${vlang.value}`"
                    >
                      <form
                        @submit.prevent="
                          handleSubmit(
                            updateContent(
                              'introduction-section-setting',
                              content['introduction_' + vlang.value],
                              `VFormIntroduction${vlang.value}`,
                              false,
                              true
                            )
                          )
                        "
                      >
                        <div class="row">
                          <div class="col-12">
                            <div class="row">
                              <div class="col-12">
                                <BoField 
                                  label="Title"
                                  placeholder="e.g. Your journey to Jakarta Running Festival (JRF) starts with collecting your race pack!"
                                  v-model="((content['introduction_' + vlang.value] || {}).value || {}).title"
                                  :rules="(mrValidation.introduction_setting || {}).title"
                                  :editFormId="`introduction_section_${vlang.value}`"
                                  :editFormValue="editFormId"
                                />
                              </div>
                              <div class="col-12">
                                <BoTextarea
                                  label="Description"
                                  placeholder="e.g. Dive into the excitement of race day by ensuring you collect your pack on time"
                                  v-model="((content['introduction_' + vlang.value] || {}).value || {}).description"
                                  :rules="(mrValidation.introduction_setting || {}).description"
                                  :editFormId="`introduction_section_${vlang.value}`"
                                  :editFormValue="editFormId"
                                />
                              </div>
                              <div class="col-md-8">
                                <div class="form-group">
                                  <label>Image 1</label>
                                  <template v-if="editFormId == `introduction_section_${vlang.value}`">
                                    <Uploader
                                      :readonly="!moduleRole('Edit')"
                                      v-model="((content['introduction_' + vlang.value] || {}).value || {}).fallback_img_1"
                                      :param="{ thumbnail: true }"
                                      :squarePreview="true"
                                      type="race_expo_image_1"
                                      label="Image 1"
                                      @data="v => ((content['introduction_' + vlang.value] || {}).value || {}).img_1 = v"
                                    />
                                    <VValidate
                                      name="Image 1"
                                      v-model="((content['introduction_' + vlang.value] || {}).value || {}).img_1"
                                      :rules="mrValidation.introduction_setting.img_1"
                                    />
                                  </template>
                                  <template v-else>
                                    <img :src="uploader(((content['introduction_' + vlang.value] || {}).value || {}).fallback_img_1)" :alt="((content['introduction_' + vlang.value] || {}).value || {}).alt_img_1" class="w-100" style="height: 240px; object-fit: cover">
                                  </template>
                                </div>
                                <div class="form-group">
                                  <BoField 
                                    label="Alt Image 1"
                                    placeholder="e.g. Image 1"
                                    v-model="((content['introduction_' + vlang.value] || {}).value || {}).alt_img_1"
                                    :rules="(mrValidation.introduction_setting || {}).alt_img_1"
                                    :editFormId="`introduction_section_${vlang.value}`"
                                    :editFormValue="editFormId"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label>Image 2</label>
                                  <template v-if="editFormId == `introduction_section_${vlang.value}`">
                                    <Uploader
                                      :readonly="!moduleRole('Edit')"
                                      v-model="((content['introduction_' + vlang.value] || {}).value || {}).fallback_img_2"
                                      :param="{ thumbnail: true }"
                                      :squarePreview="true"
                                      type="race_expo_image_2"
                                      label="Image 2"
                                      @data="v => ((content['introduction_' + vlang.value] || {}).value || {}).img_2 = v"
                                    />
                                    <VValidate
                                      name="Image 1"
                                      v-model="((content['introduction_' + vlang.value] || {}).value || {}).img_2"
                                      :rules="mrValidation.introduction_setting.img_2"
                                    />
                                  </template>
                                  <template v-else>
                                    <img :src="uploader(((content['introduction_' + vlang.value] || {}).value || {}).fallback_img_2)" :alt="((content['introduction_' + vlang.value] || {}).value || {}).alt_img_2" class="w-100" style="height: 240px; object-fit: cover">
                                  </template>
                                </div>
                                <div class="form-group">
                                  <BoField 
                                    label="Alt Image 2"
                                    placeholder="e.g. Image 2"
                                    v-model="((content['introduction_' + vlang.value] || {}).value || {}).alt_img_2"
                                    :rules="(mrValidation.introduction_setting || {}).alt_img_2"
                                    :editFormId="`introduction_section_${vlang.value}`"
                                    :editFormValue="editFormId"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <b-col md="12" v-if="editFormId == `introduction_section_${vlang.value}`">
                            <div class="text-right mt-4">
                              <b-button
                                type="button"
                                @click="cancelEditForm"
                                class="btn btn-rounded btn_light mr-2"
                              >
                                Cancel
                              </b-button>
                              <b-button 
                                v-if="moduleRole('Edit')"
                                type="submit"
                                variant="primary"
                                class="btn-rounded"
                              >
                                Update
                              </b-button>
                            </div>
                          </b-col>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h5 class="card-title">Schedule Section</h5>
                  </div>
                  <div class="col-6 ml-auto">
                    <div class="text-right">
                      <button
                        @click.prevent="toggleEditForm(`schedule_section_${vlang.value}`)"
                        class="btn btn-primary btn-rounded"
                      >
                        <i class="fas fa-edit m-r-10"></i> Update Content
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <ValidationObserver
                      v-slot="{ handleSubmit }"
                      :ref="`VFormSchedule${vlang.value}`"
                    >
                      <form
                        @submit.prevent="
                          handleSubmit(
                            updateContent(
                              'schedule-section-setting',
                              content['when_where_' + vlang.value],
                              `VFormSchedule${vlang.value}`,
                              false,
                              true
                            )
                          )
                        "
                      >
                        <div class="row">
                          <b-col md="12">
                            <div class="form-group">
                              <label class="control-label">
                                Show Section
                                <span class="text-danger mr5">*</span>
                              </label>
                              <template v-if="editFormId == `schedule_section_${vlang.value}`">
                                <div>
                                  <InputRadio
                                    v-model="((content['when_where_' + vlang.value] || {}).value || {}).is_show"
                                    label="Yes"
                                    name="show"
                                    option="Y"
                                  />
                                  <InputRadio
                                    v-model="((content['when_where_' + vlang.value] || {}).value || {}).is_show"
                                    label="No"
                                    name="show"
                                    option="N"
                                  />
                                </div>
                                <VValidate
                                  name="Status"
                                  v-model="((content['when_where_' + vlang.value] || {}).value || {}).is_show"
                                  rules="required"
                                />
                              </template>
                              <template v-else>
                                <p>{{ ((content['when_where_' + vlang.value] || {}).value || {}).is_show == 'Y' ? 'Yes': 'No'}}</p>
                              </template>
                            </div>
                          </b-col>
                          <div class="col-12">
                            <BoField 
                              label="Title"
                              placeholder="e.g. Schedule"
                              v-model="((content['when_where_' + vlang.value] || {}).value || {}).title"
                              :rules="(mrValidation.schedule_setting || {}).title"
                              :editFormId="`schedule_section_${vlang.value}`"
                              :editFormValue="editFormId"
                            />
                          </div>
                          <div class="col-md-12">
                            <label>Schedule Data</label>
                            <div class="row">
                              <template v-for="(v,k) in ((content['when_where_' + vlang.value] || {}).value || {}).data">
                                <div class="col-12" :key="k">
                                  <div class="my-2 d-flex">
                                    <template v-if="editFormId != `schedule_section_${vlang.value}`">
                                      <h5 class="fw-600 mb-0 w-25">{{ v['title'] }}</h5>
                                      <div class="w-75" v-html="v['description']"></div>
                                    </template>
                                    <template v-else>
                                      <div class="button-el d-flex justify-content-between">
                                        <span># {{ k + 1}}</span>
                                      </div>
                                      <div class="mx-3">
                                        <label>Title <span class="text-danger">*</span></label>
                                        <b-form-input
                                          :id="`title${k}${vlang.value}`"
                                          placeholder="e.g. Sunday"
                                          v-model="v['title']"
                                        ></b-form-input>
                                        <VValidate
                                          :vid="`title${k}${vlang.value}`"
                                          name="Title"
                                          v-model="v['title']"
                                          :rules="(mrValidation.schedule_data || {}).title"
                                        />
                                      </div>
                                      <div class="mx-3 w-100">
                                        <label>Description <span class="text-danger">*</span></label>
                                        <ckeditor
                                          :editor="editor"
                                          v-model="v['description']"
                                          :config="editorConfig"
                                        ></ckeditor>
                                        <VValidate
                                          :vid="`aboutDescription${vlang.value}`"
                                          name="Title"
                                          v-model="v['description']"
                                          :rules="(mrValidation.schedule_data || {}).description"
                                        />
                                      </div>
                                      <div>
                                        <b-button
                                          v-if="moduleRole('Edit')"
                                          variant="danger"
                                          size="sm"
                                          pill
                                          v-b-tooltip.hover="'Delete'"
                                          @click="deleteItem(((content['when_where_' + vlang.value] || {}).value || {}).data, k)"
                                        >
                                          <i class="fas fa-trash-alt" />
                                        </b-button>
                                      </div>
                                    </template>
                                  </div>
                                </div>
                              </template>
                              <template v-if="editFormId == `schedule_section_${vlang.value}`">
                                <div class="col-12 mt-3">
                                  <div class="border rounded mb-3 px-4 py-3 d-flex justify-content-center align-items-center">
                                    <div class="d-flex align-items-center text-decoration-underline">
                                      <a href="javascript:void(0)" @click="addNewSchedule(((content['when_where_' + vlang.value] || {}).value || {}).data)">
                                        <i class="fa fa-plus-circle"></i>
                                        <span class="mx-2">Add New</span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </div>
                          </div>
                          <hr />
                          <b-col md="12" v-if="editFormId == `schedule_section_${vlang.value}`">
                            <div class="text-right mt-4">
                              <b-button
                                type="button"
                                @click="cancelEditForm"
                                class="btn btn-rounded btn_light mr-2"
                              >
                                Cancel
                              </b-button>
                              <b-button 
                                v-if="moduleRole('Edit')"
                                type="submit"
                                variant="primary"
                                class="btn-rounded"
                              >
                                Update
                              </b-button>
                            </div>
                          </b-col>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h5 class="card-title">About Expo Section</h5>
                  </div>
                  <div class="col-6 ml-auto">
                    <div class="text-right">
                      <button
                        @click.prevent="toggleEditForm(`about_expo_section_${vlang.value}`)"
                        class="btn btn-primary btn-rounded"
                      >
                        <i class="fas fa-edit m-r-10"></i> Update Content
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <ValidationObserver
                      v-slot="{ handleSubmit }"
                      :ref="`VFormAboutExpo${vlang.value}`"
                    >
                      <form
                        @submit.prevent="
                          handleSubmit(
                            updateContent(
                              'about-expo-section-setting',
                              content['about_expo_' + vlang.value],
                              `VFormAboutExpo${vlang.value}`,
                              false,
                              true
                            )
                          )
                        "
                      >
                        <div class="row">
                          <b-col md="12">
                            <div class="form-group">
                              <label class="control-label">
                                Show Section
                                <span class="text-danger mr5">*</span>
                              </label>
                              <template v-if="editFormId == `about_expo_section_${vlang.value}`">
                                <div>
                                  <InputRadio
                                    v-model="((content['about_expo_' + vlang.value] || {}).value || {}).is_show"
                                    label="Yes"
                                    name="show"
                                    option="Y"
                                  />
                                  <InputRadio
                                    v-model="((content['about_expo_' + vlang.value] || {}).value || {}).is_show"
                                    label="No"
                                    name="show"
                                    option="N"
                                  />
                                </div>
                                <VValidate
                                  name="Status"
                                  v-model="((content['about_expo_' + vlang.value] || {}).value || {}).is_show"
                                  rules="required"
                                />
                              </template>
                              <template v-else>
                                <p>{{ ((content['about_expo_' + vlang.value] || {}).value || {}).is_show == 'Y' ? 'Yes': 'No'}}</p>
                              </template>
                            </div>
                          </b-col>
                          <div class="col-12">
                            <BoField 
                              label="Title"
                              placeholder="e.g. Schedule"
                              v-model="((content['about_expo_' + vlang.value] || {}).value || {}).title"
                              :rules="(mrValidation.about_expo_setting || {}).title"
                              :editFormId="`about_expo_section_${vlang.value}`"
                              :editFormValue="editFormId"
                            />
                          </div>
                          <div class="col-md-12">
                            <label>Description <span class="text-danger">*</span></label>
                            <template v-if="editFormId != `about_expo_section_${vlang.value}`">
                              <div v-html="((content['about_expo_' + vlang.value] || {}).value || {}).description"></div>
                            </template>
                            <template v-else>
                              <ckeditor
                                :editor="editor"
                                v-model="((content['about_expo_' + vlang.value] || {}).value || {}).description"
                                :config="editorConfig"
                              ></ckeditor>
                              <VValidate
                                :vid="`aboutDescription${vlang.value}`"
                                name="Title"
                                v-model="((content['about_expo_' + vlang.value] || {}).value || {}).description"
                                :rules="(mrValidation.about_expo_setting || {}).description"
                              />
                            </template>
                          </div>
                          <div class="col-12 mt-3">
                            <BoField 
                              label="Title Pricing"
                              placeholder="e.g. Pricing & Prices"
                              v-model="((content['about_expo_' + vlang.value] || {}).value || {}).title_pricing"
                              :rules="(mrValidation.about_expo_setting || {}).title_pricing"
                              :editFormId="`about_expo_section_${vlang.value}`"
                              :editFormValue="editFormId"
                            />
                          </div>
                          <div class="col-md-12">
                            <label>Pricing Data</label>
                            <div class="row">
                              <template v-for="(v,k) in ((content['about_expo_' + vlang.value] || {}).value || {}).pricing_data">
                                <div class="col-4" :key="k">
                                  <div class="border py-3 px-4 my-2">
                                    <template v-if="editFormId != `about_expo_section_${vlang.value}`">
                                      <h5 class="font-weight-bold mb-0">{{ v['title'] }}</h5>
                                      <div class="mt-2" v-html="v['description']"></div>
                                      <div class="d-flex justify-content-between mt-3">
                                        <h5 class="font-weight-bold">{{ v['additional'] }}</h5>
                                        <button type="button" class="btn btn-primary">{{ v['cta_text'] }}</button>
                                      </div>
                                    </template>
                                    <template v-else>
                                      <div class="button-el d-flex justify-content-between">
                                        <span># {{ k + 1}}</span>
                                        <b-button
                                          v-if="moduleRole('Edit')"
                                          variant="danger"
                                          size="sm"
                                          pill
                                          v-b-tooltip.hover="'Delete'"
                                          @click="deleteItem(((content['about_expo_' + vlang.value] || {}).value || {}).pricing_data, k)"
                                        >
                                          <i class="fas fa-trash-alt" />
                                        </b-button>
                                      </div>
                                      <div>
                                        <label>Title <span class="text-danger">*</span></label>
                                        <b-form-input
                                          :id="`title${k}${vlang.value}`"
                                          placeholder="e.g. Sunday"
                                          v-model="v['title']"
                                        ></b-form-input>
                                        <VValidate
                                          :vid="`title${k}${vlang.value}`"
                                          name="Title"
                                          v-model="v['title']"
                                          :rules="(mrValidation.about_expo_data || {}).title"
                                        />
                                      </div>
                                      <div class="w-100 mt-2">
                                        <label>Description <span class="text-danger">*</span></label>
                                        <ckeditor
                                          :editor="editor"
                                          v-model="v['description']"
                                          :config="editorConfig"
                                        ></ckeditor>
                                        <VValidate
                                          :vid="`aboutDescription${vlang.value}`"
                                          name="Description"
                                          v-model="v['description']"
                                          :rules="(mrValidation.about_expo_data || {}).description"
                                        />
                                      </div>
                                      <div class="mt-2">
                                        <label>Additional Text <span class="text-danger">*</span></label>
                                        <b-form-input
                                          :id="`additional${k}${vlang.value}`"
                                          placeholder="e.g. Sunday"
                                          v-model="v['additional']"
                                        ></b-form-input>
                                        <VValidate
                                          :vid="`additional${k}${vlang.value}`"
                                          name="Additional"
                                          v-model="v['additional']"
                                          :rules="(mrValidation.about_expo_data || {}).additional"
                                        />
                                      </div>

                                      <div class="mt-2">
                                        <label>CTA Text <span class="text-danger">*</span></label>
                                        <b-form-input
                                          :id="`cta_text${k}${vlang.value}`"
                                          placeholder="e.g. Sunday"
                                          v-model="v['cta_text']"
                                        ></b-form-input>
                                        <VValidate
                                          :vid="`cta_text${k}${vlang.value}`"
                                          name="CTA Text"
                                          v-model="v['cta_text']"
                                          :rules="(mrValidation.about_expo_data || {}).cta_text"
                                        />
                                      </div>

                                    </template>
                                  </div>
                                </div>
                              </template>
                              <template v-if="editFormId == `about_expo_section_${vlang.value}`">
                                <div class="col-4 mt-2">
                                  <div class="border rounded mb-3 px-4 py-3 d-flex justify-content-center align-items-center">
                                    <div class="d-flex align-items-center text-decoration-underline">
                                      <a href="javascript:void(0)" @click="addNewPricing(((content['about_expo_' + vlang.value] || {}).value || {}).pricing_data)">
                                        <i class="fa fa-plus-circle"></i>
                                        <span class="mx-2">Add New</span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </div>
                          </div>
                          <hr />
                          <b-col md="12" v-if="editFormId == `about_expo_section_${vlang.value}`">
                            <div class="text-right mt-4">
                              <b-button
                                type="button"
                                @click="cancelEditForm"
                                class="btn btn-rounded btn_light mr-2"
                              >
                                Cancel
                              </b-button>
                              <b-button 
                                v-if="moduleRole('Edit')"
                                type="submit"
                                variant="primary"
                                class="btn-rounded"
                              >
                                Update
                              </b-button>
                            </div>
                          </b-col>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h5 class="card-title">Event Section</h5>
                  </div>
                  <div class="col-6 ml-auto">
                    <div class="text-right">
                      <button
                        @click.prevent="toggleEditForm(`event_section_${vlang.value}`)"
                        class="btn btn-primary btn-rounded"
                      >
                        <i class="fas fa-edit m-r-10"></i> Update Content
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <ValidationObserver
                      v-slot="{ handleSubmit }"
                      :ref="`VFormAboutEvent${vlang.value}`"
                    >
                      <form
                        @submit.prevent="
                          handleSubmit(
                            updateContent(
                              'event-section-setting',
                              content['event_' + vlang.value],
                              `VFormAboutEvent${vlang.value}`,
                              false,
                              true
                            )
                          )
                        "
                      >
                        <div class="row">
                          <b-col md="12">
                            <div class="form-group">
                              <label class="control-label">
                                Show Section
                                <span class="text-danger mr5">*</span>
                              </label>
                              <template v-if="editFormId == `event_section_${vlang.value}`">
                                <div>
                                  <InputRadio
                                    v-model="((content['event_' + vlang.value] || {}).value || {}).is_show"
                                    label="Yes"
                                    name="show"
                                    option="Y"
                                  />
                                  <InputRadio
                                    v-model="((content['event_' + vlang.value] || {}).value || {}).is_show"
                                    label="No"
                                    name="show"
                                    option="N"
                                  />
                                </div>
                                <VValidate
                                  name="Status"
                                  v-model="((content['event_' + vlang.value] || {}).value || {}).is_show"
                                  rules="required"
                                />
                              </template>
                              <template v-else>
                                <p>{{ ((content['event_' + vlang.value] || {}).value || {}).is_show == 'Y' ? 'Yes': 'No'}}</p>
                              </template>
                            </div>
                          </b-col>
                          <div class="col-12">
                            <BoField 
                              label="Title"
                              placeholder="e.g. Schedule"
                              v-model="((content['event_' + vlang.value] || {}).value || {}).title"
                              :rules="(mrValidation.event_setting || {}).title"
                              :editFormId="`event_section_${vlang.value}`"
                              :editFormValue="editFormId"
                            />
                          </div>
                          <div class="col-md-12">
                            <label>Event Data</label>
                            <div class="row">
                              <template v-for="(v,k) in ((content['event_' + vlang.value] || {}).value || {}).data">
                                <div class="col-6" :key="k">
                                  <div class="card-bg bg-img border rounded mb-3 px-3 py-3" :style="editFormId != `event_section_${vlang.value}` ? 'height: 100px;' : ''">
                                    <template v-if="editFormId != `event_section_${vlang.value}`">
                                      <img :src="uploader(v.fallback_img)" :alt="v.alt_img">
                                      <h5 class="font-weight-bold mb-0">{{ v['title'] }}</h5>
                                    </template>
                                    <template v-else>
                                      <div class="content-edit w-100">
                                        <div class="button-el d-flex justify-content-between">
                                          <span># {{ k + 1}}</span>
                                          <b-button
                                            v-if="moduleRole('Edit')"
                                            variant="danger"
                                            size="sm"
                                            pill
                                            v-b-tooltip.hover="'Delete'"
                                            @click="deleteItem(((content['event_' + vlang.value] || {}).value || {}).data, k)"
                                          >
                                            <i class="fas fa-trash-alt" />
                                          </b-button>
                                        </div>
                                        <div>
                                          <label>Image <span class="text-danger">*</span></label>
                                          <Uploader
                                            :readonly="!moduleRole('Edit')"
                                            v-model="v.fallback_img"
                                            :param="{ thumbnail: true }"
                                            :squarePreview="true"
                                            type="race_expo_event"
                                            label="Image"
                                            @data="o => v.img = o"
                                          />
                                          <VValidate
                                            name="Image"
                                            v-model="v.img"
                                            :rules="mrValidation.event_data.img"
                                          />
                                        </div>
                                        <div class="mt-3">
                                          <label>Alt Image <span class="text-danger">*</span></label>
                                          <b-form-input
                                            :id="`alt_img${k}${vlang.value}`"
                                            placeholder="e.g. BIB Number Picture"
                                            v-model="v['alt_img']"
                                          ></b-form-input>
                                          <VValidate
                                            :vid="`alt_img${k}${vlang.value}`"
                                            name="Alt Image"
                                            v-model="v['alt_img']"
                                            :rules="(mrValidation.event_data || {}).alt_img"
                                          />
                                        </div>
                                        <div class="mt-3">
                                          <label>Title <span class="text-danger">*</span></label>
                                          <b-form-input
                                            :id="`title${k}${vlang.value}`"
                                            placeholder="e.g. Sunday"
                                            v-model="v['title']"
                                          ></b-form-input>
                                          <VValidate
                                            :vid="`title${k}${vlang.value}`"
                                            name="Title"
                                            v-model="v['title']"
                                            :rules="(mrValidation.event_data || {}).title"
                                          />
                                        </div>
                                      </div>
                                    </template>
                                  </div>
                                </div>
                              </template>
                              <template v-if="editFormId == `event_section_${vlang.value}`">
                                <div class="col-6 mt-2">
                                  <div class="border rounded mb-3 px-4 py-3 d-flex justify-content-center align-items-center">
                                    <div class="d-flex align-items-center text-decoration-underline">
                                      <a href="javascript:void(0)" @click="addNewEvent(((content['event_' + vlang.value] || {}).value || {}).data)">
                                        <i class="fa fa-plus-circle"></i>
                                        <span class="mx-2">Add New</span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </div>
                          </div>
                          <hr />
                          <b-col md="12" v-if="editFormId == `event_section_${vlang.value}`">
                            <div class="text-right mt-4">
                              <b-button
                                type="button"
                                @click="cancelEditForm"
                                class="btn btn-rounded btn_light mr-2"
                              >
                                Cancel
                              </b-button>
                              <b-button 
                                v-if="moduleRole('Edit')"
                                type="submit"
                                variant="primary"
                                class="btn-rounded"
                              >
                                Update
                              </b-button>
                            </div>
                          </b-col>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h5 class="card-title">Festival Area Section</h5>
                  </div>
                  <div class="col-6 ml-auto">
                    <div class="text-right">
                      <button
                        @click.prevent="toggleEditForm(`highlight_section_${vlang.value}`)"
                        class="btn btn-primary btn-rounded"
                      >
                        <i class="fas fa-edit m-r-10"></i> Update Content
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <ValidationObserver
                      v-slot="{ handleSubmit }"
                      :ref="`VFormHighlight${vlang.value}`"
                    >
                      <form
                        @submit.prevent="
                          handleSubmit(
                            updateContent(
                              'highlight-section-setting',
                              content['highlight_' + vlang.value],
                              `VFormHighlight${vlang.value}`,
                              false,
                              true
                            )
                          )
                        "
                      >
                        <div class="row">
                          <div class="col-12">
                            <BoField 
                              label="Title"
                              placeholder="e.g. Festival Area Highlight"
                              v-model="((content['highlight_' + vlang.value] || {}).value || {}).title"
                              :rules="(mrValidation.highlight_setting || {}).title"
                              :editFormId="`highlight_section_${vlang.value}`"
                              :editFormValue="editFormId"
                            />
                          </div>
                          <div class="col-md-12">
                            <label>Area Highlight</label>
                            <div class="row">
                              <template v-for="(v,k) in ((content['highlight_' + vlang.value] || {}).value || {}).data">
                                <div class="col-4" :key="k">
                                  <div class="border rounded mb-3 px-4 py-3">
                                    <template v-if="editFormId != `highlight_section_${vlang.value}`">
                                      <img :src="uploader(v['img'])" alt="" class="w-25">
                                      <h5 class="font-weight-bold">{{ v['title'] }}</h5>
                                      <span>{{ v['description'] }}</span>
                                    </template>
                                    <template v-else>
                                      <div class="button-el d-flex justify-content-between">
                                        <span># {{ k + 1}}</span>
                                        <b-button
                                          v-if="moduleRole('Edit')"
                                          variant="danger"
                                          size="sm"
                                          pill
                                          v-b-tooltip.hover="'Delete'"
                                          @click="deleteItem(((content['highlight_' + vlang.value] || {}).value || {}).data, k)"
                                        >
                                          <i class="fas fa-trash-alt" />
                                        </b-button>
                                      </div>
                                      <div>
                                        <b-form-group>
                                          <label>
                                            Image <span class="text-danger mr5">*</span>
                                          </label>
                                          <Uploader
                                            :readonly="!moduleRole('Edit')"
                                            v-model="((content['highlight_' + vlang.value] || {}).value || {}).data[k]['fallback_img']"
                                            :param="{ thumbnail: true }"
                                            :squarePreview="true"
                                            type="race_expo_festival_icon"
                                            label="Image"
                                            @data="v => ((content['highlight_' + vlang.value] || {}).value || {}).data[k]['img'] = v"
                                          />
                                          <VValidate
                                            name="Image"
                                            v-model="((content['highlight_' + vlang.value] || {}).value || {}).data[k]['img']"
                                            :rules="mrValidation.highlight_setting.img"
                                          />
                                        </b-form-group>
                                      </div>
                                      <div class="my-3">
                                        <label>Title <span class="text-danger">*</span></label>
                                        <b-form-input
                                          :id="`title${k}${vlang.value}`"
                                          placeholder="e.g. Food Bazaar"
                                          v-model="v['title']"
                                        ></b-form-input>
                                        <VValidate
                                          :vid="`title${k}${vlang.value}`"
                                          name="Title"
                                          v-model="v['title']"
                                          :rules="(mrValidation.highlight_data || {}).title"
                                        />
                                      </div>
                                      <div class="my-3">
                                        <BoTextarea
                                          label="Description"
                                          placeholder="e.g. Find delicious culinary options at the food bazaar"
                                          v-model="v['description']"
                                          :rules="(mrValidation.location_setting || {}).value"
                                          :editFormId="`highlight_section_${vlang.value}`"
                                          :editFormValue="editFormId"
                                        />
                                      </div>
                                    </template>
                                  </div>
                                </div>
                              </template>
                              <template v-if="editFormId == `highlight_section_${vlang.value}`">
                                <div class="col-4">
                                  <div class="border rounded mb-3 px-4 py-3 d-flex justify-content-center align-items-center">
                                    <div class="d-flex align-items-center text-decoration-underline">
                                      <a href="javascript:void(0)" @click="addNewSchedule(((content['highlight_' + vlang.value] || {}).value || {}).data)">
                                        <i class="fa fa-plus-circle"></i>
                                        <span class="mx-2">Add New</span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </div>
                          </div>
                          <hr />
                          <b-col md="12" v-if="editFormId == `highlight_section_${vlang.value}`">
                            <div class="text-right mt-4">
                              <b-button
                                type="button"
                                @click="cancelEditForm"
                                class="btn btn-rounded btn_light mr-2"
                              >
                                Cancel
                              </b-button>
                              <b-button 
                                v-if="moduleRole('Edit')"
                                type="submit"
                                variant="primary"
                                class="btn-rounded"
                              >
                                Update
                              </b-button>
                            </div>
                          </b-col>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h5 class="card-title">Exhibitor Section</h5>
                  </div>
                  <div class="col-6 ml-auto">
                    <div class="text-right">
                      <button
                        @click.prevent="toggleEditForm(`exhibitor_section_${vlang.value}`)"
                        class="btn btn-primary btn-rounded"
                      >
                        <i class="fas fa-edit m-r-10"></i> Update Content
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <ValidationObserver
                      v-slot="{ handleSubmit }"
                      :ref="`VFormExhibitor${vlang.value}`"
                    >
                      <form
                        @submit.prevent="
                          handleSubmit(
                            updateContent(
                              'exhibitor-section-setting',
                              content['exhibitor_' + vlang.value],
                              `VFormExhibitor${vlang.value}`,
                              false,
                              true
                            )
                          )
                        "
                      >
                        <div class="row">
                          <div class="col-12">
                            <BoField 
                              label="Title"
                              placeholder="e.g. Festival Area Highlight"
                              v-model="((content['exhibitor_' + vlang.value] || {}).value || {}).title"
                              :rules="(mrValidation.exhibitor_setting || {}).title"
                              :editFormId="`exhibitor_section_${vlang.value}`"
                              :editFormValue="editFormId"
                            />
                          </div>
                          <div class="col-md-12">
                            <label>Exhibitor Data</label>
                            <div class="row">
                              <template v-for="(v,k) in ((content['exhibitor_' + vlang.value] || {}).value || {}).data">
                                <div class="col-md-3" :key="k">
                                  <div class="border rounded mb-3 px-4 py-3">
                                    <template v-if="editFormId != `exhibitor_section_${vlang.value}`">
                                      <img :src="uploader(v['fallback_img'])" :alt="v['alt_image']" class="w-100">
                                    </template>
                                    <template v-else>
                                      <div class="button-el d-flex justify-content-between">
                                        <span># {{ k + 1}}</span>
                                        <b-button
                                          v-if="moduleRole('Edit')"
                                          variant="danger"
                                          size="sm"
                                          pill
                                          v-b-tooltip.hover="'Delete'"
                                          @click="deleteItem(((content['exhibitor_' + vlang.value] || {}).value || {}).data, k)"
                                        >
                                          <i class="fas fa-trash-alt" />
                                        </b-button>
                                      </div>
                                      <div>
                                        <b-form-group>
                                          <label>
                                            Image <span class="text-danger mr5">*</span>
                                          </label>
                                          <Uploader
                                            :readonly="!moduleRole('Edit')"
                                            v-model="v['fallback_img']"
                                            :param="{ thumbnail: true }"
                                            :squarePreview="true"
                                            type="race_expo_exhibitor"
                                            label="Image"
                                            @data="o => v['img'] = o"
                                          />
                                          <VValidate
                                            name="Image"
                                            v-model="v['img']"
                                            :rules="mrValidation.exhibitor_setting.img"
                                          />
                                        </b-form-group>
                                      </div>
                                      <div class="mt-3">
                                        <label>Alt Image <span class="text-danger">*</span></label>
                                        <b-form-input
                                          :id="`alt_img${k}${vlang.value}`"
                                          placeholder="e.g. Food Bazaar"
                                          v-model="v['alt_img']"
                                        ></b-form-input>
                                        <VValidate
                                          :vid="`alt_img${k}${vlang.value}`"
                                          name="Alt Image"
                                          v-model="v['alt_img']"
                                          :rules="(mrValidation.exhibitor_data || {}).alt_img"
                                        />
                                      </div>
                                      <div class="mt-3">
                                        <label>URL Web <span class="text-danger">*</span></label>
                                        <b-form-input
                                          :id="`url${k}${vlang.value}`"
                                          placeholder="e.g. https://jakartarunningfestival.id"
                                          v-model="v['url']"
                                        ></b-form-input>
                                        <VValidate
                                          :vid="`url${k}${vlang.value}`"
                                          name="URL Web"
                                          v-model="v['url']"
                                          :rules="(mrValidation.exhibitor_data || {}).url"
                                        />
                                      </div>
                                    </template>
                                  </div>
                                </div>
                              </template>
                              <template v-if="editFormId == `exhibitor_section_${vlang.value}`">
                                <div class="col-4">
                                  <div class="border rounded mb-3 px-4 py-3 d-flex justify-content-center align-items-center">
                                    <div class="d-flex align-items-center text-decoration-underline">
                                      <a href="javascript:void(0)" @click="addNewExhibitor(((content['exhibitor_' + vlang.value] || {}).value || {}).data)">
                                        <i class="fa fa-plus-circle"></i>
                                        <span class="mx-2">Add New</span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </div>
                          </div>
                          
                          <div class="w-100">
                            <hr />
                          </div>

                          <b-col md="12" v-if="editFormId == `exhibitor_section_${vlang.value}`">
                            <div class="text-right mt-4">
                              <b-button
                                type="button"
                                @click="cancelEditForm"
                                class="btn btn-rounded btn_light mr-2"
                              >
                                Cancel
                              </b-button>
                              <b-button 
                                v-if="moduleRole('Edit')"
                                type="submit"
                                variant="primary"
                                class="btn-rounded"
                              >
                                Update
                              </b-button>
                            </div>
                          </b-col>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h5 class="card-title">Event Layout</h5>
                  </div>
                  <div class="col-6 ml-auto">
                    <div class="text-right">
                      <button
                        @click.prevent="toggleEditForm(`event_layout_${vlang.value}`)"
                        class="btn btn-primary btn-rounded"
                      >
                        <i class="fas fa-edit m-r-10"></i> Update Content
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <ValidationObserver
                      v-slot="{ handleSubmit }"
                      :ref="`VFormExhibitor${vlang.value}`"
                    >
                      <form
                        @submit.prevent="
                          handleSubmit(
                            updateContent(
                              'exhibitor-section-setting',
                              content['exhibitor_' + vlang.value],
                              `VFormExhibitor${vlang.value}`,
                              false,
                              true
                            )
                          )
                        "
                      >
                        <div class="row">
                          <div class="col-8">
                            <div class="row">
                              <div class="col-md-12">
                                <b-form-group>
                                  <label>
                                    Show Layout
                                    <span class="text-danger mr5">*</span>
                                  </label>
                                  <template v-if="editFormId == `event_layout_${vlang.value}`">
                                    <div>
                                      <InputRadio v-model="((content['exhibitor_' + vlang.value] || {}).value || {}).show_layout" name="status" label="Yes" option="Y" />
                                      <InputRadio v-model="((content['exhibitor_' + vlang.value] || {}).value || {}).show_layout" name="status" label="No" option="N" />
                                    </div>
                                  </template>
                                  <template v-else>
                                    <p>{{ ((content['exhibitor_' + vlang.value] || {}).value || {}).show_layout == 'Y' ? 'Yes' : 'No' }}</p>
                                  </template>
                                </b-form-group>
                              </div>
                              <div class="col-6">
                                <BoField 
                                  label="Title Event Layout"
                                  placeholder="e.g. Event Layout"
                                  v-model="((content['exhibitor_' + vlang.value] || {}).value || {}).title_event_layout"
                                  :rules="(mrValidation.exhibitor_setting || {}).title_event_layout"
                                  :editFormId="`event_layout_${vlang.value}`"
                                  :editFormValue="editFormId"
                                />
                              </div>
                              <div class="col-6">
                                <BoField 
                                  label="Description Event Layout"
                                  placeholder="e.g. Event Layout Picture"
                                  v-model="((content['exhibitor_' + vlang.value] || {}).value || {}).description_event_layout"
                                  :rules="(mrValidation.exhibitor_setting || {}).description_event_layout"
                                  :editFormId="`event_layout_${vlang.value}`"
                                  :editFormValue="editFormId"
                                />
                              </div>
                              <div class="col-6">
                                <BoField 
                                  label="Text CTA Event Layout"
                                  placeholder="e.g. Download Now"
                                  v-model="((content['exhibitor_' + vlang.value] || {}).value || {}).cta_text_layout"
                                  :rules="(mrValidation.exhibitor_setting || {}).cta_text_layout"
                                  :editFormId="`event_layout_${vlang.value}`"
                                  :editFormValue="editFormId"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="row">
                              <div class="col-md-12">
                                <b-form-group>
                                  <label>
                                    Event Layout Preview Image
                                  </label>
                                  <br>
                                  <template v-if="editFormId == `event_layout_${vlang.value}`">
                                    <Uploader
                                      :readonly="!moduleRole('Edit')"
                                      v-model="((content['exhibitor_' + vlang.value] || {}).value || {}).fallback_img_layout_preview"
                                      :param="{ thumbnail: true }"
                                      :squarePreview="true"
                                      type="event_layout_preview_image"
                                      label="Image"
                                      @data="v => ((content['exhibitor_' + vlang.value] || {}).value || {}).img_layout_preview = v"
                                    />
                                  </template>
                                  <template v-else>
                                    <img :src="uploader(((content['exhibitor_' + vlang.value] || {}).value || {}).fallback_img_layout_preview)" :alt="((content['exhibitor_' + vlang.value] || {}).value || {}).alt_img_layout" class="w-100">
                                  </template>
                                </b-form-group>
                                <b-form-group>
                                  <template v-if="editFormId == `event_layout_${vlang.value}`">
                                  <label>
                                    Event Layout
                                  </label>
                                  </template>
                                  <br>
                                  <template v-if="editFormId == `event_layout_${vlang.value}`">
                                    <Uploader
                                      :readonly="!moduleRole('Edit')"
                                      :param="{ thumbnail: false }"
                                      :squarePreview="false"
                                      type="race_expo_event_layout"
                                      upload-type="documents"
                                      label="PDF"
                                      :value="((content['exhibitor_' + vlang.value] || {}).value || {}).img_layout"
                                      @data="pathfile => ((content['exhibitor_' + vlang.value] || {}).value || {}).img_layout = pathfile"
                                      :remove-action="true"
                                    />
                                  </template>
                                </b-form-group>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <b-col md="12" v-if="editFormId == `event_layout_${vlang.value}`">
                            <div class="text-right mt-4">
                              <b-button
                                type="button"
                                @click="cancelEditForm"
                                class="btn btn-rounded btn_light mr-2"
                              >
                                Cancel
                              </b-button>
                              <b-button 
                                v-if="moduleRole('Edit')"
                                type="submit"
                                variant="primary"
                                class="btn-rounded"
                              >
                                Update
                              </b-button>
                            </div>
                          </b-col>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h5 class="card-title">Additional Info Section</h5>
                  </div>
                  <div class="col-6 ml-auto">
                    <div class="text-right">
                      <button
                        @click.prevent="toggleEditForm(`additional_info_section_${vlang.value}`)"
                        class="btn btn-primary btn-rounded"
                      >
                        <i class="fas fa-edit m-r-10"></i> Update Content
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <ValidationObserver
                      v-slot="{ handleSubmit }"
                      :ref="`VFormAdditionalInfo${vlang.value}`"
                    >
                      <form
                        @submit.prevent="
                          handleSubmit(
                            updateContent(
                              'additional-info-section-setting',
                              content['additional_info_' + vlang.value],
                              `VFormAdditionalInfo${vlang.value}`,
                              false,
                              true
                            )
                          )
                        "
                      >
                        <div class="row">
                          <div class="col-12">
                            <BoField 
                              label="Title"
                              placeholder="e.g. Festival Area Highlight"
                              v-model="((content['additional_info_' + vlang.value] || {}).value || {}).title"
                              :rules="(mrValidation.additional_info_setting || {}).title"
                              :editFormId="`additional_info_section_${vlang.value}`"
                              :editFormValue="editFormId"
                            />
                          </div>
                          <div class="col-md-12">
                            <label>FAQ Data</label>
                            <div class="row">
                              <template v-for="(v,k) in ((content['additional_info_' + vlang.value] || {}).value || {}).data">
                                <div class="col-md-12" :key="k">
                                  <div class="border rounded mb-3 px-4" :class="editFormId == `additional_info_section_${vlang.value}` ? 'py-3': 'pt-3'">
                                    <template v-if="editFormId != `additional_info_section_${vlang.value}`">
                                      <p class="font-weight-bold">{{ v.title }}</p>
                                      <div v-html="v.description"></div>
                                    </template>
                                    <template v-else>
                                      <div class="button-el d-flex justify-content-between">
                                        <span># {{ k + 1}}</span>
                                        <b-button
                                          v-if="moduleRole('Edit')"
                                          variant="danger"
                                          size="sm"
                                          pill
                                          v-b-tooltip.hover="'Delete'"
                                          @click="deleteItem(((content['additional_info_' + vlang.value] || {}).value || {}).data, k)"
                                        >
                                          <i class="fas fa-trash-alt" />
                                        </b-button>
                                      </div>
                                      <div class="mt-1">
                                        <label>Title <span class="text-danger">*</span></label>
                                        <b-form-input
                                          :id="`title${k}${vlang.value}`"
                                          placeholder="e.g. Food Bazaar"
                                          v-model="v['title']"
                                        ></b-form-input>
                                        <VValidate
                                          :vid="`title${k}${vlang.value}`"
                                          name="Title"
                                          v-model="v['title']"
                                          :rules="(mrValidation.additional_info_data || {}).title"
                                        />
                                      </div>
                                      <div class="mt-3">
                                        <label>Description <span class="text-danger">*</span></label>
                                        <template v-if="editFormId != `additional_info_section_${vlang.value}`">
                                          <div v-html="v['description']"></div>
                                        </template>
                                        <template v-else>
                                          <ckeditor
                                            :editor="editor"
                                            v-model="v['description']"
                                            :config="editorConfig"
                                          ></ckeditor>
                                          <VValidate
                                            :vid="`aboutDescription${vlang.value}`"
                                            name="Description"
                                            v-model="v['description']"
                                            :rules="(mrValidation.additional_info_data || {}).description"
                                          />
                                        </template>
                                      </div>
                                    </template>
                                  </div>
                                </div>
                              </template>
                              <template v-if="editFormId == `additional_info_section_${vlang.value}`">
                                <div class="col-12">
                                  <div class="border rounded mb-3 px-4 py-3 d-flex justify-content-center align-items-center">
                                    <div class="d-flex align-items-center text-decoration-underline">
                                      <a href="javascript:void(0)" @click="addNewAdditionalInfo(((content['additional_info_' + vlang.value] || {}).value || {}).data)">
                                        <i class="fa fa-plus-circle"></i>
                                        <span class="mx-2">Add New</span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </div>
                          </div>
                          <hr />
                          <b-col md="12" v-if="editFormId == `additional_info_section_${vlang.value}`">
                            <div class="text-right mt-4">
                              <b-button
                                type="button"
                                @click="cancelEditForm"
                                class="btn btn-rounded btn_light mr-2"
                              >
                                Cancel
                              </b-button>
                              <b-button 
                                v-if="moduleRole('Edit')"
                                type="submit"
                                variant="primary"
                                class="btn-rounded"
                              >
                                Update
                              </b-button>
                            </div>
                          </b-col>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>

          </b-tab>
        </template>

        <template v-for="(vlangseo,klangseo) in lang">
          <b-tab :title="`SEO Settings - ${vlangseo.text}`" :key="`seo_${klangseo}`">
            <div class="card">
              <div class="card-header">
                <div class="row">
                  <div class="col-10">
                    <h5 class="card-title">SEO Settings</h5>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <ValidationObserver 
                      v-slot="{ handleSubmit }" 
                      :ref="`VFormSeo${vlangseo.value}`"
                    >
                      <form
                        @submit.prevent="
                          handleSubmit(
                            updateContent(
                              'seo-setting',
                              content['seo_' + vlangseo.value],
                              `VFormSeo${vlangseo.value}`,
                              false,
                              true
                            )
                          )
                        "
                      >
                        <div class="row">
                          <div class="col-12">
                            <div class="row">
                              <div class="col-12 col-md-8">
                                <div class="form-group">
                                  <label :for="`seoTitle${vlangseo.value}`">
                                    Title
                                    <span class="text-danger mr5">*</span>
                                  </label>
                                  <template v-if="editFormId == `seo${vlangseo.value}`">
                                    <b-form-input
                                      :id="`seoTitle${vlangseo.value}`"
                                      v-model="((content['seo_' + vlangseo.value] || {}).value || {}).title"
                                      :formatter="(v) => v.replace(/\s\s\s+/, ' ')"
                                    ></b-form-input>
                                    <VValidate
                                      :vid="`seoTitle${vlangseo.value}`"
                                      name="Title"
                                      v-model="((content['seo_' + vlangseo.value] || {}).value || {}).title"
                                      rules="required|min:3|max:200"
                                    />
                                  </template>
                                  <template v-else>
                                    <p class="font-weight-bold">{{ ((content['seo_' + vlangseo.value] || {}).value || {}).title }}</p>
                                  </template>
                                </div>
                              </div>
                              <div class="col-12 col-md-8">
                                <div class="form-group">
                                  <label>
                                    Description
                                    <span class="text-danger mr5">*</span>
                                  </label>
                                  <template v-if="editFormId == `seo${vlangseo.value}`">
                                    <b-form-textarea
                                      :id="`seoDescription${vlangseo.value}`"
                                      v-model="((content['seo_' + vlangseo.value] || {}).value || {}).description"
                                      :formatter="(v) => v.replace(/\s\s\s+/, ' ')"
                                      rows="5"
                                    ></b-form-textarea>
                                    <VValidate
                                      :vid="`seoDescription${vlangseo.value}`"
                                      name="Description"
                                      v-model="((content['seo_' + vlangseo.value] || {}).value || {}).description"
                                      rules="required|min:10|max:500"
                                    />
                                  </template>
                                  <template v-else>
                                    <div v-html="((content['seo_' + vlangseo.value] || {}).value || {}).description"></div>
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row" v-if="moduleRole('Edit')">
                          <div class="col-12">
                            <hr />
                            <div class="text-right">
                              <template v-if="editFormId == `seo${vlangseo.value}`">
                                <button
                                  type="button"
                                  @click="editFormId = ''"
                                  class="btn btn-rounded btn-light mr-2"
                                >
                                  Cancel
                                </button>
                                <button type="submit" class="btn btn-rounded btn-primary">
                                  Save
                                </button>
                              </template>
                              <template v-else>
                                <button
                                  type="button"
                                  @click="editFormId = `seo${vlangseo.value}`"
                                  class="btn btn-rounded btn-primary"
                                >
                                  Ubah
                                </button>
                              </template>
                            </div>
                          </div>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </template>
      </b-tabs>

      <ValidationObserver v-slot="{ handleSubmit }" ref="VFormHeroImage">
        <b-modal
          id="heroImageModal"
          size="md"
          title="Edit Hero Image"
          @ok.prevent="
            handleSubmit(
              updateContent(
                'hero-image',
                inputHeroImage,
                'VFormHeroImage',
                'heroImageModal'
              )
            )
          "
        >
          <template #default>
            <div class="infoHTML"></div>
            <b-form 
              @submit.prevent="
                  handleSubmit(
                    updateContent(
                      'hero-image',
                      inputHeroImage,
                      'VFormHeroImage'
                    )
                  )
                "
            >
              <b-row>
                <b-col md="12">
                  <BoField 
                    label="Sub Title"
                    placeholder="e.g. RACE EXPO"
                    v-model="((inputHeroImage || {}).value || {}).sub_title"
                    :rules="mrValidation.sub_title"
                  />
                </b-col>
                <b-col md="12">
                  <BoField 
                    label="Title"
                    placeholder="e.g. Jakarta Running Festival 2024"
                    v-model="((inputHeroImage || {}).value || {}).title"
                    :rules="mrValidation.title"
                  />
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label>
                      Image <span class="text-danger mr5">*</span>
                    </label>
                    <Uploader
                      :readonly="!moduleRole('Edit')"
                      v-model="((inputHeroImage || {}).value || {}).fallback_img"
                      :param="{ thumbnail: true }"
                      :squarePreview="true"
                      type="hero_image"
                      label="Image"
                      @data="v => ((inputHeroImage || {}).value || {}).img = v"
                    />
                    <VValidate
                      name="Image"
                      v-model="((inputHeroImage || {}).value || {}).img"
                      :rules="mrValidation.hero_image.img"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label>
                      Small Image <span class="text-danger mr5">*</span>
                    </label>
                    <Uploader
                      :readonly="!moduleRole('Edit')"
                      v-model="((inputHeroImage || {}).value || {}).fallback_img_small"
                      :param="{ thumbnail: true }"
                      :squarePreview="true"
                      type="hero_small"
                      label="Image"
                      @data="v => ((inputHeroImage || {}).value || {}).img_small = v"
                    />
                    <VValidate
                      name="Image"
                      v-model="((inputHeroImage || {}).value || {}).img_small"
                      :rules="mrValidation.hero_image.img_small"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <BoField 
                    label="Alt Image"
                    placeholder="e.g. Hero Image"
                    v-model="((inputHeroImage || {}).value || {}).alt_img"
                    :rules="mrValidation.alt_img"
                  />
                </b-col>
                <hr />
              </b-row>
            </b-form>
          </template>

          <template #modal-footer="{ ok, cancel }">
            <b-button variant="outline-secondary" @click="cancel()">
              Cancel
            </b-button>
            <b-button variant="info" @click="ok()">
              Submit
            </b-button>
          </template>
        </b-modal>
      </ValidationObserver>

    </div>
  </div>
</template>

<script>
import GlobalVue from "@libs/Global";
import PageTitle from "@view/include/PageTitle";

import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@libs/ckeditor5-custom-build/build/ckeditor";
import { extend } from "vee-validate";
import assign from "lodash/assign";

import BoCard from '@/components/BoCard.vue'
import BoCardImg from '@/components/BoCardImg.vue'

export default {
  extends: GlobalVue,

  components: {
    PageTitle,
    BoCard,
    BoCardImg,
    ckeditor: CKEditor.component,
  },

  data() {
    return {
      // GENERAL
      lang: [
        {
          text: "English",
          value: "en",
        },
        {
          text: "Indonesian",
          value: "id",
        }
      ],
      editor: ClassicEditor,
      editorConfig: {
        fontColor: {
          colors: [
            {
              color: "hsl(221, 99%, 43%)",
              label: "Blue",
            },
            {
              color: "hsl(0, 83%, 47%)",
              label: "Red",
            },
            {
              color: "hsl(93, 69%, 49%)",
              label: "Green",
            },
            {
              color: "hsl(322, 100%, 62%)",
              label: "Pink",
            },
            {
              color: "hsl(0, 0%, 0%)",
              label: "Black",
            },
            {
              color: "hsl(0, 0%, 30%)",
              label: "Dim grey",
            },
            {
              color: "hsl(0, 0%, 60%)",
              label: "Grey",
            },
            {
              color: "hsl(0, 0%, 90%)",
              label: "Light grey",
            },
            {
              color: "hsl(0, 0%, 100%)",
              label: "White",
              hasBorder: true,
            },
          ],
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
            {
              model: "heading3",
              view: "h3",
              title: "Heading 3",
              class: "ck-heading_heading3",
            },
            {
              model: "heading4",
              view: "h4",
              title: "Heading 4",
              class: "ck-heading_heading4",
            },
            {
              model: "heading5",
              view: "h5",
              title: "Heading 5",
              class: "ck-heading_heading5",
            },
            {
              model: "heading6",
              view: "h6",
              title: "Heading 6",
              class: "ck-heading_heading6",
            },
          ],
        },
        ui: {
          viewportOffset: {
            top: 65,
          },
        },
        toolbar: {
          items: ["heading", "|", "bold", "fontColor", "link"],
        },
      },

      // ROW
      content: {},

      editFormId: "",

      // INPUT
      inputHeroImage: {}
    };
  },

  created() {
    this.apiGet();
  },

  methods: {
    // General

    updateContent(type, input, IDFORM, modalId = false, usingKey = false) {
      this.doSubmit(
        "/do/" + this.modulePage,
        assign(
          {
            type,
          },
          {},
          {
            as_value: input,
          }
        ),
        (type) => {
          if (type === "success") {
            this.apiGet();
            this.editFormId = "";
            if (modalId) {
              this.$bvModal.hide(modalId);
            }
          }
        },
        "POST",
        IDFORM,
        usingKey
      );
    },

    // Hero Image
    editHeroImage(data) {
      this.inputHeroImage = {
        ...data
      }

      this.$bvModal.show('heroImageModal')
    },

    // Schedule
    addNewSchedule(data) {
      let inputData = {
        title: '',
        description: '',
      }

      data.push(inputData)
    },

    addNewPricing(data) {
      let inputData = {
        title: '',
        description: '',
        additional: '',
        is_show_cta: '',
        cta_text: '',
      }

      data.push(inputData)
    },

    addNewAdditionalInfo(data) {
      let inputData = {
        title: '',
        description: '',
      }

      data.push(inputData)
    },

    addNewEvent(data) {
      let inputData = {
        title: '',
        img: '',
        fallback_img: '',
        alt_img: '',
      }

      data.push(inputData)
    },

    addNewExhibitor(data) {
      let inputData = {
        img: '',
        fallback_img: '',
        alt_img: '',
        url: '',
      }

      data.push(inputData)
    },

    deleteItem(data, index) {
      if(index > -1) {
        data.splice(index, 1);
      }
    }
  },
  mounted() {
    extend("editor", {
      message: "The {_field_} field must be at least 10 characters",
      validate: (value) => {
        try {
          return this.sanitize(value).length >= 10;
        } catch (error) {
          console.log(error);
        }
      },
    });
  },
}
  
</script>